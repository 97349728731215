import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { YoutubeFilled, RightOutlined } from '@ant-design/icons';
import { Button as Btn, Icon } from '@hospy/hospy-ui';
import { motion, AnimatePresence } from 'framer-motion';
import { useAppSelector } from '@hospy/store';

const DEFAULT_MESSAGE = '¡Hola! ¿Cómo podemos ayudarte?';

const Chatwoot = () => {
	const [isOpen, setIsOpen] = useState(false);
	const chatRef = useRef<HTMLDivElement>(null);

	const [message, setMessage] = useState(DEFAULT_MESSAGE);

	const { user } = useAppSelector(({ user }) => user);
	const currentProperty = user?.properties?.find(
		(e) => e._id === user?.currentProperty || ''
	);

	useEffect(() => {
		if (currentProperty) {
			setMessage(
				`Hola,\nNecesito soporte para ${currentProperty?.name || ''}`
			);
		} else {
			setMessage(DEFAULT_MESSAGE);
		}
	}, [currentProperty]);

	const toggleChat = () => {
		setIsOpen(!isOpen);
	};

	const closeChat = () => {
		setIsOpen(false);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			chatRef.current &&
			!chatRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const [showChat, setShowChat] = useState(false);

	const handleClickShowChat = () => {
		setShowChat(true);
	};

	{
		/*return (
        <>
		 <Container  >
			<ContainerHeaeder>
				<div className="head-home">
					<h3>Hospy</h3> 
					<ContainerHeaderAvatar>
						<img src="https://sistemadecotizaciones.files.wordpress.com/2020/08/supportmale.png" />
					</ContainerHeaderAvatar>
				</div>

				<div className="get-new">
					<div id="get-label">Soporte</div>
					<div id="get-nama">Servicio al cliente</div>
				</div>
			</ContainerHeaeder>

			<ContainerBody>
				<ContainerBodyFirstMessage>
					<span>¡Hola! ¿Qué puedo hacer por ti?</span>
				</ContainerBodyFirstMessage>
				<ContainerBodyBlanterMessage>
					<textarea
						id="chat-input"
                        style={
                            {
                             
    border: "none",
    fontFamily: "Arial, sans-serif",
    width: "100%",
    height: "20px",
    outline: "none",
    resize: "none"

                           }
                        }
						placeholder="Escribe un mensaje"
					></textarea>
					<a  style={
                        {
    color: "#555",
    width: "55px",
    margin: "-5px 0 0 5px",
    fontWeight: "700",
    padding: "8px",
    background: "#eee",
    borderRadius: "10px",
}
                    } id="send-it">
						Enviar
					</a>
				</ContainerBodyBlanterMessage>
			</ContainerBody>
			<a style={{
    position: "absolute",
    top: "5px",
    right: "15px",
    color: "#fff",
    fontSize: "30px"
}
} >
				×
			</a>
		</Container> 

        <LinkShowChat 
            target="_blank"
            href="https://api.whatsapp.com/send?phone=13217886880&text=%C2%A1Hola%21%20%C2%BFC%C3%B3mo%20podemos%20ayudarte%3F"
            title='Show Chat'>
            <img
                alt="icon whatsapp"
                src={
                    'https://cdn.hospy.co/third-party-app-icons/whatsapp.png'
                }
                style={{ 
                    maxWidth: 24,
                    transform: "scale(1.2)",
                    margin: "0 10px 0 0"
                }}
                />
            Chatea con nosotros
        </LinkShowChat>
    </>
	);*/
	}
	return (
		<ChatContainer ref={chatRef}>
			<AnimatePresence>
				{isOpen && (
					<AnimatedChatOptions
						initial={{
							opacity: 0,
							scale: 0.5,
							originX: 1,
							originY: 1
						}}
						animate={{
							opacity: 1,
							scale: 1,
							originX: 1,
							originY: 1
						}}
						exit={{
							opacity: 0,
							scale: 0.5,
							originX: 1,
							originY: 1
						}}
						transition={{ duration: 0.2, ease: 'easeInOut' }}
					>
						<Title>¿Necesitas ayuda?</Title>

						<ItemButton
							icon={
								<Icon
									materialOutlined="whatsapp-bold"
									style={{
										fontSize: '24px',
										color: '#34b142',
										marginBottom: '3px'
									}}
								/>
							}
							onClick={() =>
								window.open(
									`https://api.whatsapp.com/send?phone=+13217886880&text=${encodeURI(
										message
									)}`,
									'_blank'
								)
							}
						>
							Chatear con agentes
							<RightOutlined />
						</ItemButton>

						<ItemButton
							icon={
								<YoutubeFilled
									style={{
										fontSize: '24px',
										color: '#f9372d',
										marginTop: '3px'
									}}
								/>
							}
							onClick={() =>
								window.open('https://hospy.co/videos', '_blank')
							}
						>
							Buscar un tutorial
							<RightOutlined />
						</ItemButton>
					</AnimatedChatOptions>
				)}
			</AnimatePresence>

			<div
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'end',
					paddingTop: '22px'
				}}
				onClick={closeChat}
			>
				<ChatButton
					type="primary"
					shape="circle"
					icon={
						<Icon
							material="mode_comment"
							style={{
								fontSize: '25px',
								color: 'white',
								marginTop: '2px',
								marginRight: '2px'
							}}
						/>
					}
					onClick={(e) => {
						e.stopPropagation();
						toggleChat();
					}}
					size="large"
				/>
			</div>
		</ChatContainer>
	);
};

export default Chatwoot;

const Title = styled(Typography.Text)`
	display: block;
	text-align: center;
	font-size: ${({ theme }) => theme.title.size.small}px;
	margin-bottom: 15px;
	font-weight: bold;
`;

const ChatContainer = styled.div`
	position: fixed;
	bottom: 25px;
	right: 30px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	transition: all 0.3s ease;
	z-index: 10;
`;

const AnimatedChatOptions = styled(motion.div)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	gap: 20px;
	//margin-bottom: 22px;
	z-index: 100;
	background-color: #fff;
	border-radius: 10px;
	padding: 30px 15px 70px 15px;
	width: 100%;
	width: 350px;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

	@media (max-width: 400px) {
		width: 100%;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: -34px;
		right: 12px;
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-top: 35px solid #fff;
		pointer-events: none;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: -36px;
		right: 13px;
		width: 0;
		height: 0;
		border-left: 21px solid transparent;
		border-right: 21px solid transparent;
		border-top: 33px solid rgba(0, 0, 0, 0.2);
		z-index: -10;
		filter: blur(2px);
		pointer-events: none;
	}
`;

const ItemButton = styled(Btn)`
	display: inline-flex;
	border-radius: 8px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 10px;
	padding: 30px 15px;
`;

const ChatButton = styled(Btn)`
	background-color: #0171ff !important;
	box-shadow: ${({ theme }) =>
		theme.boxShadow.level2('rgba(1, 113, 255, 0.5)')} !important;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	border: none;
	& svg {
		font-size: 20px;
	}

	:hover,
	:active,
	:focus {
		background-color: #0259ca !important;
		border: none;
	}
`;

const Container = styled.div`
	position: fixed;
	background: #fff;
	width: 350px;
	border-radius: 10px;
	box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
	bottom: 90px;
	right: 30px;
	overflow: hidden;
	z-index: 99;
	animation-name: showchat;
	animation-duration: 1s;
	transform: scale(1);

	// hide
	// display: none;
	// animation-name: showhide;
	// animation-duration: 1.5s;
	// transform: scale(1);
	// opacity: 1;

	@media (max-width: 480px) {
		#whatsapp-chat {
			width: auto;
			left: 5%;
			right: 5%;
			font-size: 80%;
		}
	}
`;

const ContainerHeaeder = styled.div`
	background: #095e54;
	color: #fff;
	padding: 20px;

	h3 {
		margin: 0 0 10px;
	}

	p {
		font-size: 14px;
		line-height: 1.7;
		margin: 0;
	}
`;
const ContainerHeaderAvatar = styled.div`
	position: relative;

	img {
		border-radius: 100%;
		width: 50px;
		float: left;
		margin: 0 10px 0 0;
	}

	// :before {
	//     content: "\f232";
	//     z-index: 1;
	//     font-family: "Font Awesome 5 Brands";
	//     background: #23ab23;
	//     color: #fff;
	//     padding: 4px 5px;
	//     border-radius: 100%;
	//     position: absolute;
	//     top: 30px;
	//     left: 30px;
	// }
`;

const ContainerBody = styled.div`
	display: block;
`;
const ContainerBodyFirstMessage = styled.div`
	background: #f5f5f5;
	padding: 30px;
	text-align: center;

	span {
		background: #e2e2e2;
		color: #333;
		font-size: 14.2px;
		line-height: 1.7;
		border-radius: 10px;
		padding: 15px 20px;
		display: inline-block;
	}
`;
const ContainerBodyBlanterMessage = styled.div`
	display: flex;
	color: #444;
	padding: 20px;
	font-size: 12.5px;
	text-align: center;
	border-top: 1px solid #ddd;
`;

const LinkShowChat = styled.a`
	background: #fff;
	color: #404040;
	position: fixed;
	display: flex;
	font-weight: 40;
	justify-content: space-between;
	z-index: 98;
	bottom: 25px;
	right: 30px;
	font-size: 15px;
	padding: 10px 20px;
	border-radius: 30px;
	box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
`;
