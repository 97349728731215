/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingAppView } from '@hospy/shared-components';
import { setAppName, useAppDispatch, useAppSelector } from '@hospy/store';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import AppRoutes from '../routes/app-routes';
// import { setIntercomUser } from '@hospy/util-common';

const App = () => {
	const { isAuth } = useAppSelector((state: any) => state.user);
	const { user } = useAppSelector((state: any) => state.user);
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setAppName('MAIN'));
	}, []);

	if (isAuth && !user) return <LoadingAppView />;
	// setIntercomUser(user);
	return (
		<React.Suspense fallback={<LoadingAppView />}>
			<AnimatePresence>
				<AppRoutes />
			</AnimatePresence>
		</React.Suspense>
	);
};

export default App;
